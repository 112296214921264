<template>
    <div class="c-app flex-row align-items-center" v-bind:style="{ 'background-image': 'url(' + background + ')' }" style="background-size: cover;">
      <Loading :isActive="loading" />
      <CContainer>
        <CRow class="justify-content-center">
          <CCol md="8">
            <CCardGroup>
              <CCard class="p-4">
                <CCardBody>
                  <CForm v-on:keyup.enter="onSubmit">
                    <h2>Painel Administrativo</h2>
                    <small v-show="!!loginError" class="text-danger">
                      {{ loginError }}
                    </small>
                    <CInput
                      placeholder="CPF, Email ou Matrícula"
                      autocomplete="username email"
                      v-model="user"
                    />
                    <CInput
                      placeholder="Senha"
                      type="password"
                      autocomplete="curent-password"
                      v-model="pass"
                    />
                    <CRow>
                      <CCol col="6" class="text-left">
                        <CButton color="primary" class="px-4" @click="onSubmit">
                          Login
                        </CButton>
                      </CCol>
                      <!--<CCol col="6" class="text-right">-->
                        <!--<CButton color="link" class="px-0">Forgot password?</CButton>-->
                        <!--<CButton color="link" class="d-lg-none">Register now!</CButton>-->
                      <!--</CCol>-->
                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>
              <CCard
                color="light"
                text-color="white"
                class="text-center py-5 d-md-down-none"
                body-wrapper
              >
                <CCardBody>
                  <img v-bind:src="logo" alt="logo" class="">
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
</template>
<script>
import { getToken } from '@/helpers/apiRequest'
import config from '../../config'
import {get} from "../../helpers/apiRequest";
import {loadCargas} from "../../helpers/cargas";

export default {
  name: 'login',
  data () {
    return {
      logo: config.api+'/images/logo',
      background: config.api+'/images/background/ADMIN',
      user: '',
      pass: '',
      loginError: false,
      loading: false,
    }
  },
  methods: {
    onSubmit () {
      this.loading = true
      getToken({ cpf: this.user, senha: this.pass })
        .then(json => {
          localStorage.setItem('token', json.token)
          loadCargas(
            json.token,
            (carga) => {
              this.$router.push({ name: 'home' });
              this.loading = false;
            },
            (err) => {
              this.loading    = false;
              this.loginError = err.message;
            }
          );
      })
      .catch((error) => {
        this.loading = false
        this.loginError = error.message;
      })
    }
  }
}
</script>
